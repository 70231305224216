import React from 'react';

import PdfTemplate from '../components/PdfTemplate';
import debug from '../utils/debug';

const printResumeTemplate = (): React.ReactElement => {
  debug('Loading printResumeTemplate page');

  return (
    <div>
      <PdfTemplate />
    </div>
  );
};

export default printResumeTemplate;
